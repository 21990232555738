import styled from "styled-components";


export const ContainerRedefinirSenha = styled.div`
    width: 100%;
    background-image: url('/textura.jpg');
    background-size: cover;
    height: 100vh;
    overflow-y: auto;
    padding-top: 40px;
    padding-bottom: 100px;
    display: flex;
    align-items: start;
    box-sizing: border-box;
    overflow-x: hidden;
    position: relative;
    flex-direction: column;
    align-items: center;
    z-index: 0; 

    @media (max-width: 920px){
        padding: 80px 20px;
    }
`;


export const ImageBackground = styled.img`
    position: fixed;
    top: 30%;
    z-index: 1; 

    height: auto;
    opacity: 0.2; /* Ajuste a opacidade se necessário */
`;

export const ContainerTitle = styled.h1`

`;

export const ContainerContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 999;
`;

export const RedefineBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2{
        margin: 0;
        // margin-top: 100px;
        color: rgba(0,0,0,0.8);
    }
`;

export const Box = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    position: relative;

    p{
        margin: 0;
        font-size: 28px;
        font-weight: 800;
    }

    .searchResult{
        position: absolute;
        top: 70px;
        width: 300px;
        box-sizing: border-box;
        max-height: 300px;
        background: white;
        border-radius: 3px;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: scroll;
        gap: 20px;

        .item{
            margin: 10px;
            box-sizing: border-box;
        }
    }

    &.meuDeus{
        margin-bottom: 100px;
        box-sizing: border-box;

        .inputBoxSearch{
            width: 100%;
            display: grid;
            grid-template-columns: 8fr 1fr;
            box-sizing: border-box;
            padding: 0 30px;
            gap: 10px;

            input {
                height: 40px;
                padding-left: 30px;
                box-sizing: border-box;
                font-size: 18px;
                color: rgba(0,0,0,0.7);
        
                border-radius: 3px;
                border: 3px solid rgba(0,0,0,0.4);;
                box-shadow: 2px 1px 4px rgba(0,0,0,0.6);
            }

            button{
                width: 100%;
                height: 40px;
                box-sizing: border-box;
                border: 3px solid rgba(0,0,0,0.4);
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
            }
        }
    }
`;  

export const RedefineButton = styled.button`
    width: 300px;
    padding: 0;
    box-sizing: border-box;
    height: 40px;
    border-radius: 0;
    cursor: pointer;
    font-weight: 500;
    background: linear-gradient(-70deg, #000000, #000000, #36364a, #000000, #000000);
    transition: .3s;

    &:hover{
        transform: scale(0.95);
    }
`;


export const SearchedClients = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .columns{
        width: 100%;
        display: grid;
        grid-template-columns: 3fr 2fr 3fr;
        gap: 10px;
        background: linear-gradient(to right, rgba(20, 20, 20, 1), rgba(50, 50, 50, 1), rgba(20, 20, 20, 1));
        box-sizing: border-box;
        border-bottom: 3px solid blue;

        span{
            color: white;
            height: 40px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .rowSelected{
        width: 100%;
        display: grid;
        grid-template-columns: 6fr 4fr 6fr 1fr;
        cursor: pointer;
        transition: .3s;
        background: black;
        padding: 0;
        box-sizing: border-box;

        span{
            color: white;
            height: 40px;
            width: 100%;
            font-weight: 600;
            display: flex;
            justify-content: center;
            box-sizing: border-box;
            align-items: center;
        }

        &:hover{
            transform: scale(0.99);
            background: black;
        }
    }

    .tabelona{
        width: 100%;
        max-height: 450px;
        background: linear-gradient(to right, rgba(20, 20, 20, 1), rgba(50, 50, 50, 1), rgba(20, 20, 20, 1));
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        overflow-x: hidden;
        padding: 0;

        .row{
            width: 100%;
            display: grid;
            grid-template-columns: 3fr 2fr 3fr;
            cursor: pointer;
            transition: .3s;

            span{
                color: white;
                height: 40px;
                width: 100%;
                font-weight: 600;
                display: flex;
                justify-content: center;
                box-sizing: border-box;
                align-items: center;
            }

            &:hover{
                transform: scale(0.99);
                background: black;
            }
        }
    }
`;

export const PesquisaContainer = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 0 30px;
`;

export const SelectedContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
`;

export const BoxInfo = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    p{
        margin: 0;
        font-size: 26px;
        font-weight: 600;
    }

    input{
        min-width: 300px;
        max-width: 400px;
        height: 50px;
        box-sizing: border-box;
        text-align: center;
        font-size: 18px;
        border-radius: 6px;
        border: 3px solid rgba(0,0,0,0.4);
    }
`;

// export const BoxInfo = styled.div``;

// export const BoxInfo = styled.div``;


