import React, { useState, useEffect } from "react";
import * as S from './ContratosStyle';
import { formatDate, formatCurrencyBRL, calcularTempoPassado, areDatesEqual } from "../ASSETS/assets";
import PaginaContrato from "../PaginaDoContrato/PaginaContrato";
import * as XLSX from 'xlsx'; // Importa a biblioteca XLSX
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";
import Loading from "../Loader";

const PAGE_SIZE = 10;

export default function Contratos() {
    const { purchases, getPurchases, totalPurchases } = useContext(AuthContext);
    const [search, setSearch] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [orderFilter, setOrderFilter] = useState("desc");
    const [filterPages, setFilterPages] = useState(30);
    const [offset, setOffset] = useState(0);
    const [load, setLoad] = useState(false);
    const [itemsPerPage, setItemPerPage] = useState(10);
    const [selectedContract, setSelectedContract] = useState(null)
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = (currentPage - 1) * itemsPerPage;

    const currentContracts = purchases.slice(indexOfFirstItem, indexOfLastItem);

    useEffect(() => {
        if (purchases.length === 0) {
            fetchContracts();
        }
    }, [])

    const fetchContracts = async () => {
        setLoad(true);
        try {
            await getPurchases(filterPages, offset, orderFilter, statusFilter, search);
        } catch (e) {
            console.log(e)
        }
        setLoad(false);
    };

    const handlePaginateFuture = async (type) => {
        var totalClientsCarregados = currentPage * itemsPerPage;
        if (type === "+") {
            if (totalClientsCarregados <= ((currentPage + 1) * itemsPerPage)) {
                setLoad(true)
                setFilterPages(filterPages + (itemsPerPage * 2))
                await fetchContracts();
                console.log("obtendo mais clientes.")
                setLoad(false);
            }
            setCurrentPage(currentPage + 1);
        } else {
            if (currentPage > 1) {
                setCurrentPage(currentPage - 1);
            }
        }
    }

    const valorGanho = (valorInvestido, lucroAtual) => {
        let valorINVESTIDO = (typeof valorInvestido === 'string' ? parseFloat(valorInvestido) : valorInvestido);
        let lucroATUAL = lucroAtual || 0;
        return ((lucroATUAL / 100) * valorINVESTIDO);
    };

    const handleStatus = (status) => {
        switch (status) {
            case 1:
                return 'VALORIZANDO';
            case 2:
                return 'Finalizado';
            case 3:
                return 'Cancelado';
            case 4:
                return 'Pendente';
            default:
                return 'Indefinido';
        }
    };

    const handleUnselectContract = () => {
        setSelectedContract(null);
        fetchContracts();
    }

    return (
        <S.ContratosContainer>
            <Loading load={load} />
            {selectedContract != null && (
                <PaginaContrato contratoData={selectedContract} handleClose={handleUnselectContract} />
            )}
            <S.HomeInitialContent>
                <S.PartTitle>Painel do Investidor - Modelo de Sistema</S.PartTitle>
                <S.Boxes>
                    <S.Box bgColor="#f2f2f2">
                        <S.BoxContent>
                            <S.BoxTitle>QUANTIDADE TOTAL DE CONTRATOS</S.BoxTitle>
                            <span>{totalPurchases}</span>
                        </S.BoxContent>
                    </S.Box>
                </S.Boxes>
            </S.HomeInitialContent>

            <S.Contracts>
                <S.ContractsTitle>CONTRATOS</S.ContractsTitle>
                <S.SearchAreaContent>
                    <S.SearchClient>
                        <span className="title">PESQUISE PELO CLIENTE</span>
                        <div className="box">
                            <input value={search} onChange={(e) => setSearch(e.target.value)} placeholder="NOME OU CPF" />
                            <button onClick={() => { fetchContracts(); setCurrentPage(1) }}>PESQUISAR</button>
                        </div>
                    </S.SearchClient>
                    <S.SearchArea>
                        <S.FilterDiv>
                            <h4>STATUS</h4>
                            <select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
                                <option value="">TODOS</option>
                                <option value="2">FINALIZADOS</option>
                                <option value="1">VALORIZANDO</option>
                                <option value="3">CANCELADOS</option>
                            </select>
                            <button onClick={() => { fetchContracts(); setCurrentPage(1) }}>Filtrar</button>
                        </S.FilterDiv>
                        <S.FilterDiv>
                            <h4>ORDEM</h4>
                            <select value={orderFilter} onChange={(e) => setOrderFilter(e.target.value)}>
                                <option value="desc">Decrescente</option>
                                <option value="asc">Crescente</option>
                            </select>
                            <button onClick={() => { fetchContracts(); setCurrentPage(1) }}>Filtrar</button>
                        </S.FilterDiv>
                    </S.SearchArea>
                </S.SearchAreaContent>
            </S.Contracts>

            <S.TableContainer>
                <S.Table>
                    <S.TableHeader>
                        <S.TableRow>
                            <S.TableHeaderCell>DATA DE COMPRA</S.TableHeaderCell>

                            <S.TableHeaderCell>ID</S.TableHeaderCell>
                            <S.TableHeaderCell>CLIENTE</S.TableHeaderCell>
                            <S.TableHeaderCell>CPF</S.TableHeaderCell>
                            <S.TableHeaderCell>TOKENS</S.TableHeaderCell>
                            <S.TableHeaderCell>VALOR UNI.</S.TableHeaderCell>
                            <S.TableHeaderCell>VALOR INVESTIDO</S.TableHeaderCell>
                            <S.TableHeaderCell>LUCRO ATUAL (R$)</S.TableHeaderCell>
                            <S.TableHeaderCell>LUCRO ATUAL (%)</S.TableHeaderCell>
                            <S.TableHeaderCell>FINALIZA EM</S.TableHeaderCell>
                            <S.TableHeaderCell>STATUS</S.TableHeaderCell>
                        </S.TableRow>
                    </S.TableHeader>
                    <S.TableBody>
                        {currentContracts.map((ctr, index) => (
                            <S.TableRow onClick={() => setSelectedContract(ctr)} key={index}>
                                <S.TableCell>{formatDate(ctr.PURCHASEDATE)}</S.TableCell>

                                <S.TableCell>{ctr.IDCOMPRA}</S.TableCell>
                                <S.TableCell>{ctr.CLIENT_NAME.toUpperCase()}</S.TableCell>
                                <S.TableCell>{ctr.CLIENT_CPF}</S.TableCell>
                                <S.TableCell>{ctr.COINS}</S.TableCell>
                                <S.TableCell>R$ {ctr.COINVALUE && ctr.COINVALUE.toFixed(2)}</S.TableCell>
                                <S.TableCell>R$ {ctr.TOTALSPENT && ctr.TOTALSPENT.toFixed(2)}</S.TableCell>
                                <S.TableCell>R$ {valorGanho(ctr.TOTALSPENT, ctr.RENDIMENTO_ATUAL).toFixed(2)}</S.TableCell>
                                <S.TableCell>{ctr.RENDIMENTO_ATUAL ? ctr.RENDIMENTO_ATUAL.toFixed(2) : '00.00'}%</S.TableCell>
                                <S.TableCell>{formatDate(ctr.YIELDTERM)}</S.TableCell>
                                <S.TableCell>{handleStatus(ctr.STATUS)}</S.TableCell>
                            </S.TableRow>
                        ))}
                    </S.TableBody>
                </S.Table>
            </S.TableContainer>

            <S.PaginationContainer>
                <S.PaginationButton onClick={() => { handlePaginateFuture("-") }}>
                    Anterior
                </S.PaginationButton>

                <S.PaginationButton>
                    {indexOfFirstItem} à {currentPage * itemsPerPage} de {totalPurchases}
                </S.PaginationButton>

                <S.PaginationButton onClick={() => { handlePaginateFuture("+") }}>
                    Próxima
                </S.PaginationButton>
            </S.PaginationContainer>
        </S.ContratosContainer>
    );
}


