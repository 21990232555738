import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Loading from "./Loader";
import { useContext } from "react";
import { AuthContext } from "../Context/AuthContext";

export default function Login() {
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [load, setLoad] = useState(false);
    const {login} = useContext(AuthContext);

    const handleLoginClick = async () => {
        setLoad(true);
        await login(email, pass);
        setLoad(false);
    }

    return (
        <LoginContainer>
            <Loading load={load} />
            <LoginCenter>
                <img src="./logo-golden-ouro.png" />
                <Initial>ADMIN PLATFORM</Initial>
                <Formu>
                    <FormuGroup>
                        <p>EMAIL</p>
                        <input 
                            placeholder="email"
                            type="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </FormuGroup>
                    <FormuGroup>
                        <p>SENHA</p>
                        <input 
                            type="password"
                            placeholder="senha"
                            onChange={e => setPass(e.target.value)}
                            value={pass}
                        />
                    </FormuGroup>
                </Formu>

                <Logar onClick={handleLoginClick}>ENTRAR</Logar>
            </LoginCenter>
        </LoginContainer>
    )
}

const LoginContainer = styled.div`
    width: 100%;
    height: 100vh;
    overflow:hidden;
    box-sizing: border-box;
    padding: 40px 0px;
    background-image: url("./back1.png");
    background-size: cover;
    color: #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center; 
`;

const LoginCenter = styled.div`
    width: max-content;
    padding: 40px;
    box-shadow: 3px 3px 15px rgba(0,0,0,0.6);
    background: linear-gradient(-45deg, rgba(20, 20, 20, 1), rgba(50, 50, 50, 1), rgba(20, 20, 20, 1));
    border-radius: 6px;

    img{
        width: 180px;
    }
`;

const Initial = styled.h1`
    color: gold;
    margin: 0;
    margin-bottom: 10px;
`;

const Formu = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const FormuGroup = styled.div`
    display: flex;
    flex-direction: column;

    p{
        margin: 0;
    }

    input{
        height: 30px;
        font-size: 18px;
        padding-left: 15px;
    }
`;

const Logar = styled.button`
    margin-top: 10px;
    width: 100%;
    height: 30px;
    cursor: pointer;
    background-color: #8dc6ff;
    border: 0;
    transition: .3s;

    &:hover{
        background-color: #34495e;
        color: #e4f1fe;
    }
`;
