import React, { useState, useContext, useEffect } from "react";
import * as S from "./ExtrairDadosStyle";
import { AuthContext } from "../../Context/AuthContext";
import Loading from "../Loader";

const clientStatusFilter = [];
const contratosStatusFilter = [
    { name: "Todos", value: "" },
    { name: "Valorizando", value: "1" },
    { name: "Finalizado", value: "2" },
    { name: "Cancelado", value: "3" },
    { name: "Pendente", value: "4" },
];

const saqueStatusFilter = [
    { name: "Todos", value: "" },
    { name: "Pendentes", value: "1" },
    { name: "Pagos", value: "2" },
    { name: "Cancelados", value: "3" },
];


export default function ExtrairDados() {
    const [type, setType] = useState("")
    const [statusFilter, setStatusFilter] = useState("");
    const [orderFilter, setOrderFilter] = useState("desc");
    const [filtroString, setFiltroString] = useState("");
    const { downloadExcel } = useContext(AuthContext);
    const [load, setLoad] = useState(false);

    const handleChangeType = (e) => {
        setType(e.target.value);
        setStatusFilter("");
        setOrderFilter("");
    }

    const handleDownload = async () => {
        if (type.trim() === "")
            return alert("Selecione o Dado a Ser Extraido.");
        if (type.trim() === "clientes" && statusFilter.trim() != "")
            setStatusFilter("");
        if (orderFilter.trim() === "" || orderFilter.trim() != "desc" || orderFilter.trim() != "asc")
            setOrderFilter("desc");

        setLoad(true);
        try {
            await downloadExcel(orderFilter, statusFilter, filtroString, type);
        } catch (err) {
            console.log(err);
            alert("Houve um erro ao fazer o download.");
        }
        setLoad(false);
    }

    return (
        <>
            <Loading load={load} />
            <S.ExtrairContainer>
                <S.TypeAndFilter>
                    <S.SelectBox>
                        <span className="title">Extrair: </span>
                        <select value={type} onChange={(e) => handleChangeType(e)} className="selectInfo">
                            <option value="">Selecione</option>
                            <option value="clientes">Clientes</option>
                            <option value="contratos">Contratos</option>
                            <option value="saques">Saques</option>
                        </select>
                    </S.SelectBox>

                    <S.Filters>

                        {type && type.trim() != "" && type != "clientes" ? (
                            <div className="box">
                                <span className="title">Status: </span>
                                <select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)} className="selectInfo">
                                    {type === "contratos" && contratosStatusFilter.length !== 0 && contratosStatusFilter.map((it, key) => (
                                        <option key={key} value={it.value}>{it.name}</option>
                                    ))}
                                    {type === "saques" && saqueStatusFilter.length !== 0 && saqueStatusFilter.map((it, key) => (
                                        <option key={key} value={it.value}>{it.name}</option>
                                    ))}
                                </select>
                            </div>
                        ) : (
                            <>

                            </>
                        )}
                        {type && type.trim() != "" && (
                            <>
                                <div className="box">
                                    <span className="title">Ordenação: </span>
                                    <select value={orderFilter} onChange={(e) => setOrderFilter(e.target.value)} className="selectInfo">
                                        <option value="desc">Decrescente</option>
                                        <option value="asc">Crescente</option>
                                    </select>
                                </div>

                                <div className="box">
                                    <span className="title">Cliente: </span>
                                    <input value={filtroString} onChange={(e) => setFiltroString(e.target.value)} placeholder="NOME OU CPF" className="selectInfo" />
                                </div>
                            </>
                        )}
                    </S.Filters>
                    <S.Download>
                        <button onClick={handleDownload}>Download</button>
                    </S.Download>
                </S.TypeAndFilter>
            </S.ExtrairContainer>
        </>
    )
}

