import styled from "styled-components";


export const AnteciparLucroContainer = styled.div`
    width: 100%;
    min-height: 100vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 20px;
    box-sizing: border-box;
    background: linear-gradient(-60deg, #313632, #000000 , #313632, #000000, #000000, #313632);

    h1{
        margin: 0;
        font-size: 42px;
        color: white;
        text-shadow: 3px 3px 2px rgba(0,0,0,0.4);
    }

    h4{
        margin: 0;
        margin-top: 20px;
        font-size: 22px;
        color: white;
    }
`;

export const ContractSearch = styled.div`
    width: 100%;
     display: flex;
    flex-direction: column;
    margin-top: 40px;

    h4{
        margin: 0;
        font-size: 22px;
        color: rgba(0, 100, 255, 1);
        text-shadow: 3px 3px 2px rgba(0,0,0,0.4);
    }

    input{
        height: 40px;
        border: 0;
        box-shadow: 3px 3px 2px rgba(0,0,0,0.4);
        font-size: 22px;
        box-sizing: border-box;
        text-align: center;
    }
`;

export const SearchResult = styled.div`
    width: 100%;
    max-height: 300px;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
    overflow-y: scroll;
    overflow-x: hidden;

    div{
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 20px;
        text-align: center;
        transition: .3s;
        cursor: pointer;
        padding: 10px 0;
        p, span{
            margin: 0;
            color: black;
            font-weight: 500;
            font-size: 18px;
        }

        &:hover{
            background-color: black;

            p, span{
                color: white;
            }
        }
    }
`;

export const QuandoDeseja = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    gap: 20px;
    margin-top: 40px;

    span{
        color: white;
        font-weight: 500;
    }

    input{
        height: 40px;
        border: 0;
        box-shadow: 3px 3px 2px rgba(0,0,0,0.4);
        font-size: 22px;
        box-sizing: border-box;
        text-align: center;
    }

    button{
        width: 100%;
        height: 40px;
        border: 0;
        background-color: rgba(100, 255, 0 , 1);
        font-size: 16px;
        font-weight: 600;
        color: black;
        transition: .3s;

        &:hover{
            transform: scale(0.98);
        }
    }
`;


export const ClientSearch = styled.div`
    width: 100%;
     display: flex;
    flex-direction: column;
    margin-top: 40px;

    h4{
        margin: 0;
        font-size: 22px;
        color: rgba(0, 100, 255, 1);
        text-shadow: 3px 3px 2px rgba(0,0,0,0.4);
    }

    .inputBoxFilter{
        width: 100%;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 8fr 1fr;
        gap: 10px;

        input{
            width: 100%;
            height: 40px;
            border: 0;
            box-shadow: 3px 3px 2px rgba(0,0,0,0.4);
            font-size: 22px;
            box-sizing: border-box;
            text-align: center;
        }

        button{
            width: 100%;
            height: 40px;
        }
    }
`;


// export const nome = styled.div``;

// export const nome = styled.div``;

// export const nome = styled.div``;
