import styled from "styled-components";

export const PaginaContratoContainer = styled.div`
    width: 100%;
    height: 100vh;
    overflow: hidden;
    box-sizing: border-box;
    padding: 40px 40px;
    background: linear-gradient(to right, rgba(20, 20, 20, 1), rgba(50, 50, 50, 1), rgba(20, 20, 20, 1));
    color: #f2f2f2;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    overflow-y: scroll;

    @media (max-width: 1000px){
        padding: 10px 10px;
    }

    h1 {
        margin-top: 40px;
    }
`;

export const ContratoDataContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 2fr 2fr;
    overflow: auto;
    gap: 20px;
    margin-top: 40px;

    @media(max-width: 1400px){
        grid-template-columns: 2fr 2fr;
    }

    @media(max-width: 900px){
        grid-template-columns: 2fr;
    }
`;

export const ContratoDataBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    span{
        font-size: 26px;
        text-align: center;
        width: 100%;
        font-weight: 500;
    }
    
    input{
        width: 100%;
        height: 50px;
        border: 0;
        box-shadow: 4px 4px 2px rgba(0,0,0,0.7);
        font-size: 22px;
        box-sizing: border-box;
        padding-left: 20px;
        border: 3px solid rgba(255, 255, 255, 0.2);
    }

    h4{
        margin: 0;
        font-weight: 500;
        font-size: 18px;
        color: rgba(0,220,0,1);
        width: 100%;
        text-align: end;
        cursor: pointer;
        transition: .3s;

        &:hover{color: white;}
    }

    .desabilitado{
        background-color: rgba(50,50,50,1);
        color: white;
    }

    @media (max-width: 1000px){
        span{
            font-size: 16px;
        }

        input{
            font-size: 18px;
        }
    }
`;


export const PaginaButtons = styled.div`
    width: 100%;

    display: flex;
    gap: 10px;
    justify-content: end;
`;


export const CloseButton = styled.button`

    background-color: #FFC300;
    color: #000814;
    border: 0;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #000814;
        color: #FFC300;
    }
`;

export const SaveButton = styled.button`

    background: linear-gradient(to bottom, #0c9b18, #6acc1a);
    color: #000814;
    border: 0;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #000814;
        color: #FFC300;
    }
`;

export const CancelContract = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 40px;

    span{
        font-size: 22px;
    }

    div{
        display: flex;
        width: 100%;

        input{
            width: 100%;
            text-align: center;
            font-size: 16px;
            box-sizing: border-box;
            height: 40px;
        }

        button{
            background-color: rgba(240, 30, 20, 1);
            height: 40px;
            border-radius: 0;
            cursor: pointer;
            transition: .3s;

            &:hover{
                backgound-color: rgba(0,0,0,1);
            }
        }
    }
`;

export const RealizarRecompra = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    
    span{
        font-size: 22px;
    }

    div{
        display: flex;
        width: 100%;
        gap: 10px;

        input{
            width: 100%;
            text-align: center;
            font-size: 16px;
            box-sizing: border-box;
            height: 50px;
        }

        button{
            width: 200px;
            background: rgba(100, 255, 0, 1);
            color: rgba(0, 0, 0, 1);
            font-weight: 600;
            cursor: pointer;
            transition: .3s;
            border-radius: 0;

            &:hover{
                transform: scale(0.97);
            }
        }
    }
`;

export const RevalidarContrato = styled.div`
    width: 100%;
    // background: red;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid black;
    padding: 20px 10px;
    box-sizing: border-box;
    background: linear-gradient(-45deg, rgba(20, 20, 20, 1), rgba(40, 40, 40, 1), rgba(20, 20, 20, 1));
    gap: 20px;

    .titulo{
        font-size: 22px;
        color: white;
    }

    .boxReativar{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        box-sizing: border-box;
        justify-content: center;
    }

    .confirm{
        width: 50%;
        height: 40px;
        background: rgba(40, 210, 12, 1);
        font-size: 16px;
        cursor: pointer;
    }

    .box{
        width: 300px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;

        .titleBox{
            font-size: 18px;
        }

        .inputText{
            width: 100%;
            border: 0;
            height: 45px;
            font-size: 18px;
            text-align: center;
        }
    }
`;

export const Funcoes = styled.div`
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;