import React, { useEffect, useState } from "react";
import * as HomeStyle from './HomeStyle';

export default function Home() {
    const [opSelected, setOpSelected] = useState(null);

    const handleOpClick = (op) => {
        setOpSelected(op);
    };

    const handleUnselect = () => {
        setOpSelected(null);
    };


    return (
        <>
            <HomeStyle.NewHomeContainer>
                <HomeStyle.NewHomeOptions>
                    <HomeStyle.NewHomeOptionBox onClick={() => handleOpClick("CLIENTES")}>
                        <div className="iconBox">
                            <img src="./clientes.png" />
                        </div>
                        <span className="name">CLIENTES</span>
                    </HomeStyle.NewHomeOptionBox>
                    <HomeStyle.NewHomeOptionBox onClick={() => handleOpClick("FERRAMENTAS")}>
                        <div className="iconBox">
                            <img src="./tools.png" />
                        </div>
                        <span className="name">FERRAMENTAS</span>
                    </HomeStyle.NewHomeOptionBox>
                    <HomeStyle.NewHomeOptionBox onClick={() => { window.location.href = '/funcoes' }}>
                        <div className="iconBox">
                            <img src="./grapth.png" />
                        </div>
                        <span className="name">GRÁFICOS</span>
                    </HomeStyle.NewHomeOptionBox>
                    <HomeStyle.NewHomeOptionBox onClick={() => handleOpClick("CONTRATOS")}>
                        <div className="iconBox">
                            <img src="./contratos.png" />
                        </div>
                        <span className="name">CONTRATOS</span>
                    </HomeStyle.NewHomeOptionBox>
                    <HomeStyle.NewHomeOptionBox onClick={() => handleOpClick("SAQUES")}>
                        <div className="iconBox">
                            <img src="./saque.png" />
                        </div>
                        <span className="name">SAQUES</span>
                    </HomeStyle.NewHomeOptionBox>
                    <HomeStyle.NewHomeOptionBox onClick={() => { window.location.href = '/access' }}>
                        <div className="iconBox">
                            <img src="./increase-icon.png" />
                        </div>
                        <span className="name">ACESSOS</span>
                    </HomeStyle.NewHomeOptionBox>

                </HomeStyle.NewHomeOptions>
            </HomeStyle.NewHomeContainer>

            {opSelected && (
                <HomeStyle.NewHomeContent>
                    <span onClick={handleUnselect} style={{ cursor: "pointer", float: "right" }}>x</span>

                    {opSelected === "FERRAMENTAS" ? (
                        <>
                            <HomeStyle.StyledLink to="/indicacao">
                                <HomeStyle.Option>ADICIONAR INDICAÇÃO</HomeStyle.Option>
                            </HomeStyle.StyledLink>
                            <HomeStyle.StyledLink to="/plusAnual">
                                <HomeStyle.Option>ADICIONAR PLUS</HomeStyle.Option>
                            </HomeStyle.StyledLink>
                            <HomeStyle.StyledLink to="/mensagem">
                                <HomeStyle.Option>CRIAR MENSAGEM</HomeStyle.Option>
                            </HomeStyle.StyledLink>
                            <HomeStyle.StyledLink to="/noticias">
                                <HomeStyle.Option>CRIAR NOTÍCIA</HomeStyle.Option>
                            </HomeStyle.StyledLink>
                            <HomeStyle.StyledLink to="/anteciparLucro">
                                <HomeStyle.Option>ANTECIPAR LUCRO PRA SAQUE</HomeStyle.Option>
                            </HomeStyle.StyledLink>
                        </>
                    ) : opSelected === "CLIENTES" ? (
                        <>
                            <HomeStyle.StyledLink to="/redefiniremail">
                                <HomeStyle.Option>REDEFINIR LOGIN</HomeStyle.Option>
                            </HomeStyle.StyledLink>
                            <HomeStyle.StyledLink to="/clientes">
                                <HomeStyle.Option>TODOS OS CLIENTES</HomeStyle.Option>
                            </HomeStyle.StyledLink>
                        </>
                    ) : opSelected === "CONTRATOS" ? (
                        <>
                            <HomeStyle.StyledLink to="/depositos">
                                <HomeStyle.Option>VALIDAR CONTRATOS</HomeStyle.Option>
                            </HomeStyle.StyledLink>
                            <HomeStyle.StyledLink to="/contratos">
                                <HomeStyle.Option>VER CONTRATOS</HomeStyle.Option>
                            </HomeStyle.StyledLink>
                        </>
                    ) : opSelected === "SAQUES" ? (
                        <>
                            <HomeStyle.StyledLink to="/saques">
                                <HomeStyle.Option>VALIDAR SAQUES</HomeStyle.Option>
                            </HomeStyle.StyledLink>
                            <HomeStyle.StyledLink to="/saquesFeitos">
                                <HomeStyle.Option>VER SAQUES</HomeStyle.Option>
                            </HomeStyle.StyledLink>
                        </>
                    ) : null}

                </HomeStyle.NewHomeContent>
            )}
            {/* <HomeStyle.HomeContainer>
                <HomeStyle.ImageBackground src="logo-golden.png"></HomeStyle.ImageBackground>
                <>
                    <HomeStyle.FirstMachine>
                        <HomeStyle.OptionCircle onClick={() => handleOpClick("FERRAMENTAS")}>
                            <HomeStyle.CircleOption>
                                <img src="tools.png" alt="Ferramentas" />
                            </HomeStyle.CircleOption>
                            <h1>FERRAMENTAS</h1>
                        </HomeStyle.OptionCircle>

                        <HomeStyle.OptionCircle onClick={() => { window.location.href = '/funcoes' }}>
                            <HomeStyle.CircleOption>
                                <img src="grapth.png" alt="Gráficos" />
                            </HomeStyle.CircleOption>
                            <h1>GRÁFICOS</h1>
                        </HomeStyle.OptionCircle>

                        <HomeStyle.OptionCircle onClick={() => handleOpClick("CLIENTES")}>
                            <HomeStyle.CircleOption>
                                <img src="clientes.png" alt="Clientes" />
                            </HomeStyle.CircleOption>
                            <h1>CLIENTES</h1>
                        </HomeStyle.OptionCircle>

                        <HomeStyle.OptionCircle onClick={() => handleOpClick("CONTRATOS")}>
                            <HomeStyle.CircleOption>
                                <img src="contratos.png" alt="Contratos" />
                            </HomeStyle.CircleOption>
                            <h1>CONTRATOS</h1>
                        </HomeStyle.OptionCircle>

                        <HomeStyle.OptionCircle onClick={() => handleOpClick("SAQUES")}>
                            <HomeStyle.CircleOption>
                                <img src="saque.png" alt="Saques" />
                            </HomeStyle.CircleOption>
                            <h1>SAQUES</h1>
                        </HomeStyle.OptionCircle>
                    </HomeStyle.FirstMachine>

                    {opSelected && (
                        <HomeStyle.HomeContent>
                            <span onClick={handleUnselect} style={{ cursor: "pointer", float: "right" }}>x</span>

                            {opSelected === "FERRAMENTAS" ? (
                                <>
                                    <HomeStyle.StyledLink to="/indicacao">
                                        <HomeStyle.Option>ADICIONAR INDICAÇÃO</HomeStyle.Option>
                                    </HomeStyle.StyledLink>
                                    <HomeStyle.StyledLink to="/plusAnual">
                                        <HomeStyle.Option>ADICIONAR PLUS</HomeStyle.Option>
                                    </HomeStyle.StyledLink>
                                    <HomeStyle.StyledLink to="/mensagem">
                                        <HomeStyle.Option>CRIAR MENSAGEM</HomeStyle.Option>
                                    </HomeStyle.StyledLink>
                                    <HomeStyle.StyledLink to="/noticias">
                                        <HomeStyle.Option>CRIAR NOTÍCIA</HomeStyle.Option>
                                    </HomeStyle.StyledLink>
                                    <HomeStyle.StyledLink to="/anteciparLucro">
                                        <HomeStyle.Option>ANTECIPAR LUCRO PRA SAQUE</HomeStyle.Option>
                                    </HomeStyle.StyledLink>
                                </>
                            ) : opSelected === "CLIENTES" ? (
                                <>
                                    <HomeStyle.StyledLink to="/redefiniremail">
                                        <HomeStyle.Option>REDEFINIR LOGIN</HomeStyle.Option>
                                    </HomeStyle.StyledLink>
                                    <HomeStyle.StyledLink to="/clientes">
                                        <HomeStyle.Option>TODOS OS CLIENTES</HomeStyle.Option>
                                    </HomeStyle.StyledLink>
                                    <HomeStyle.StyledLink to="/acessos">
                                        <HomeStyle.Option>ACESSOS</HomeStyle.Option>
                                    </HomeStyle.StyledLink>
                                    <HomeStyle.StyledLink to="/acertarbd">
                                        <HomeStyle.Option>ACERTAR DADOS</HomeStyle.Option>
                                    </HomeStyle.StyledLink>
                                </>
                            ) : opSelected === "CONTRATOS" ? (
                                <>
                                    <HomeStyle.StyledLink to="/depositos">
                                        <HomeStyle.Option>VALIDAR CONTRATOS</HomeStyle.Option>
                                    </HomeStyle.StyledLink>
                                    <HomeStyle.StyledLink to="/contratos">
                                        <HomeStyle.Option>VER CONTRATOS</HomeStyle.Option>
                                    </HomeStyle.StyledLink>
                                </>
                            ) : opSelected === "SAQUES" ? (
                                <>
                                    <HomeStyle.StyledLink to="/saques">
                                        <HomeStyle.Option>VALIDAR SAQUES</HomeStyle.Option>
                                    </HomeStyle.StyledLink>
                                    <HomeStyle.StyledLink to="/saquesFeitos">
                                        <HomeStyle.Option>VER SAQUES</HomeStyle.Option>
                                    </HomeStyle.StyledLink>
                                </>
                            ) : null}

                        </HomeStyle.HomeContent>
                    )}
                </>
            </HomeStyle.HomeContainer> */}
        </>

    );
}
