import styled from "styled-components";


export const PLUSContainer = styled.div`
    width: 100%;
    min-height: 100vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 20px;
    box-sizing: border-box;
    // background: linear-gradient(to right, #001D3D, #003566, #001D3D);
    background: linear-gradient(-60deg, #313632, #000000 , #313632, #000000, #000000, #313632);
    h1{
        margin: 0;
        font-size: 42px;
        color: white;
        text-shadow: 3px 3px 2px rgba(0,0,0,0.4);
    }

    h4{
        margin: 0;
        margin-top: 20px;
        font-size: 22px;
        color: white;
    }
`;

export const ClientSearch = styled.div`
    width: 100%;
     display: flex;
    flex-direction: column;
    margin-top: 40px;

    h4{
        margin: 0;
        font-size: 22px;
        color: rgba(0, 100, 255, 1);
        text-shadow: 3px 3px 2px rgba(0,0,0,0.4);
    }

    .inputBoxFilter{
        width: 100%;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 8fr 1fr;
        gap: 10px;

        input{
            width: 100%;
            height: 40px;
            border: 0;
            box-shadow: 3px 3px 2px rgba(0,0,0,0.4);
            font-size: 22px;
            box-sizing: border-box;
            text-align: center;
        }

        button{
            width: 100%;
            height: 40px;
        }
    }
`;

export const SearchResult = styled.div`
    width: 100%;
    max-height: 300px;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 20px;
    overflow-y: scroll;
    overflow-x: hidden;
    div{
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 20px;
        text-align: center;

        p{
            margin: 0;
            padding: 10px;
            color: black;
            box-sizing: border-box;
            font-weight: 500;
            cursor: pointer;
            font-size: 15px;
            transition: .3s;

            &:hover{
                color: white;
                background: black;
            }
        }
    }
`;

export const QuandoDeseja = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    gap: 20px;
    margin-top: 40px;

    SPAN{
        color: white;
        font-weight: 500;
    }

    input{
        height: 40px;
        border: 0;
        box-shadow: 3px 3px 2px rgba(0,0,0,0.4);
        font-size: 22px;
        box-sizing: border-box;
        text-align: center;
    }

    button{
        width: 100%;
        border: 0;
        background-color: rgba(100, 255, 0 , 1);
        font-size: 16px;
        font-weight: 600;
        color: black;
        transition: .3s;

        &:hover{
            transform: scale(0.98);
        }
    }
`;


export const SearchedClients = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .columns{
        width: 100%;
        display: grid;
        grid-template-columns: 3fr 2fr 3fr;
        gap: 10px;
        background: linear-gradient(to right, rgba(20, 20, 20, 1), rgba(50, 50, 50, 1), rgba(20, 20, 20, 1));
        box-sizing: border-box;
        border-bottom: 3px solid blue;

        span{
            color: white;
            height: 40px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .rowSelected{
        width: 100%;
        display: grid;
        grid-template-columns: 6fr 4fr 6fr 1fr;
        cursor: pointer;
        transition: .3s;
        background: black;
        padding: 0;
        box-sizing: border-box;

        span{
            color: white;
            height: 40px;
            width: 100%;
            font-weight: 600;
            display: flex;
            justify-content: center;
            box-sizing: border-box;
            align-items: center;
        }

        &:hover{
            transform: scale(0.99);
            background: black;
        }
    }

    .tabelona{
        width: 100%;
        max-height: 450px;
        background: linear-gradient(to right, rgba(20, 20, 20, 1), rgba(50, 50, 50, 1), rgba(20, 20, 20, 1));
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        overflow-x: hidden;
        padding: 0;

        .row{
            width: 100%;
            display: grid;
            grid-template-columns: 3fr 2fr 3fr;
            cursor: pointer;
            transition: .3s;

            span{
                color: white;
                height: 40px;
                width: 100%;
                font-weight: 600;
                display: flex;
                justify-content: center;
                box-sizing: border-box;
                align-items: center;
            }

            &:hover{
                transform: scale(0.99);
                background: black;
            }
        }
    }
`;

export const SearchArea = styled.div`
    width: 100%;
    margin-top: 20px;
`;


// export const nome = styled.div``;

// export const nome = styled.div``;

// export const nome = styled.div``;
