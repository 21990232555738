import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getDepositos, getSaques } from '../../redux/actions';
import { formatCPF, formatDateClients } from "../ASSETS/assets";
import ValidarCredenciais from "../ValidarCredenciais/ValidarCredenciais";
import * as S from './DepositosStyle';
import Loading from "../Loader";
import * as XLSX from 'xlsx';
import { AuthContext } from "../../Context/AuthContext";

export default function Depositos() {
    const { pendingPurchases, totalPendingPurchases, getPendingPurchases, getClientsForSelect, clientsForSelect } = useContext(AuthContext);
    const [search, setSearch] = useState('');
    const [purchases, setPurchases] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [modal, setModal] = useState(false);
    const [coinsQTDE, setCoinsQTDE] = useState(1);
    const [payMethod, setPayMethod] = useState('PIX');
    const [currentPage, setCurrentPage] = useState(1);
    const [modalAberto, setModalAberto] = useState(false);
    const [modalData, setModalData] = useState({});
    const [valorUni, setValorUni] = useState(450);
    const [lucroFinal, setLucroFinal] = useState(150);
    const [type, setType] = useState('DEPOSITO');
    const [mesesCotrato, setMesesContrato] = useState(36);
    const [sortOrder, setSortOrder] = useState("desc");
    const [descrip, setDescrip] = useState("");

    const [clientSearch, setClientSearch] = useState("");
    const [clientSearchResult, setClientSearchResult] = useState([]);

    const [filterPages, setFilterPages] = useState(30);
    const [offset, setOffset] = useState(0);
    const [load, setLoad] = useState(false);
    const [itemsPerPage, setItemPerPage] = useState(10);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = (currentPage - 1) * itemsPerPage;
    const currentContracts = pendingPurchases.slice(indexOfFirstItem, indexOfLastItem);

    useEffect(() => {
        if (purchases.length === 0) {
            fetchContracts();
        }
    }, [])

    const fetchContracts = async () => {
        setLoad(true);
        try {
            await getPendingPurchases(filterPages, offset, "", search);
        } catch (e) {
            console.log(e)
        }
        setLoad(false);
    };

    const fetchClients = async () => {
        setLoad(true);
        try {
            await getClientsForSelect(50, 0, clientSearch, "crescente", "");
        } catch (e) {
            console.log(e)
        }
        setLoad(false);
    };

    useEffect(() => {
        setClientSearchResult(clientsForSelect)
    }, [clientsForSelect])

    const handlePaginateFuture = async (type) => {
        var totalContratosCarregados = currentPage * itemsPerPage;
        if (type === "+") {
            if (totalContratosCarregados <= ((currentPage + 1) * itemsPerPage)) {
                setLoad(true)
                setFilterPages(filterPages + (itemsPerPage * 2))
                await fetchContracts();
                setLoad(false);
            }
            setCurrentPage(currentPage + 1);
        } else {
            if (currentPage > 1) {
                setCurrentPage(currentPage - 1);
            }
        }
    }

    const handleShowModal = () => { setModal(true); };

    const handleStatus = (status) => {
        switch (status) {
            case 1:
                return 'VALORIZANDO';
            case 2:
                return 'Finalizado';
            case 3:
                return 'Cancelado';
            case 4:
                return 'Pendente';
            default:
                return 'Indefinido';
        }
    };

    const handleOpenValidarModalDEPOSITO = (data, type) => {
        setModalData(data);
        setModalAberto(true);
        setType(type);
    };

    const handleSelectClient = (client) => {
        setSelectedClient(client);
        setClientSearch('');
        setClientSearchResult([])
    };

    const handleUnSelectClient = () => {
        setSelectedClient(null);
    };

    const handleSave = async () => {
        if (selectedClient) {
            const totalPurchaseValue = parseFloat(coinsQTDE) * parseFloat(valorUni);
            const purchaseDetails = {
                CLIENT_NAME: selectedClient.NAME,
                CLIENT_CPF: selectedClient.CPF,
                INDICADOR: selectedClient.INDICADOR ? selectedClient.INDICADOR : null,
                COINS: parseFloat(coinsQTDE),
                COINVALUE: parseFloat(valorUni),
                TOTALSPENT: parseFloat(totalPurchaseValue),
                MAXIMUMQUOTAYIELD: lucroFinal,
                MAXIMUMNUMBEROFDAYSTOYIELD: mesesCotrato,
                RENDIMENTO_ATUAL: 0,
                paymentMethod: payMethod,
                DESCRIPTION: descrip || "",
            };
            await handleOpenValidarModalDEPOSITO(purchaseDetails, 'CRIAR_DEPOSITO');
            setModal(false);
        } else {
            console.error('Nenhum cliente selecionado.');
        }
    };

    const handleName = (name1, name2) => name1 ? name1 : name2;
    const handleCPF = (cpf1, cpf2) => cpf1 ? formatCPF(cpf1) : formatCPF(cpf2);

    return (
        <S.DepositosContainer>
            {modalAberto && (
                <ValidarCredenciais
                    setModalAberto={setModalAberto}
                    type={type}
                    modalData={modalData}
                />
            )}

            <Loading load={load} />

            <S.DepositosFirstContent>
                <S.AreaTitle>VALIDAÇÃO DE CONTRATOS</S.AreaTitle>
                <S.AddDepositos onClick={handleShowModal}>+ REALIZAR UMA NOVA COMPRA</S.AddDepositos>
            </S.DepositosFirstContent>

            <S.DepositosContent>

                <S.BoxFilters>
                    <S.SearchBar>
                        <input
                            value={search}
                            onChange={e => setSearch(e.target.value)}
                            type="text"
                            placeholder="Nome do Cliente"
                        />
                        <button onClick={() => { fetchContracts(); setCurrentPage(1); }}>Pesquisar</button>
                    </S.SearchBar>

                    <S.FiltroStatus>
                        <div className="box">
                            <span className="title">ORDEM</span>
                            <select value={sortOrder} onChange={(e) => setSortOrder(e.target.value)} className="selectBox">
                                <option value="desc">Descrescente</option>
                                <option value="asc">Crescente</option>
                            </select>
                            <button onClick={() => { fetchContracts(); setCurrentPage(1) }} className='filterButton'>Filtrar</button>
                        </div>
                    </S.FiltroStatus>
                </S.BoxFilters>

                <S.DepositosTable>

                    <S.TableContainer>
                        <S.Table>
                            <S.TableHeader>
                                <S.TableRow>
                                    <S.TableHeaderCell>ID</S.TableHeaderCell>
                                    <S.TableHeaderCell>CLIENTE</S.TableHeaderCell>
                                    <S.TableHeaderCell>CPF</S.TableHeaderCell>
                                    <S.TableHeaderCell>DATA SOLICITAÇÃO</S.TableHeaderCell>
                                    <S.TableHeaderCell>TOKENS</S.TableHeaderCell>
                                    <S.TableHeaderCell>VALOR</S.TableHeaderCell>
                                    <S.TableHeaderCell>PAGAMENTO</S.TableHeaderCell>
                                    <S.TableHeaderCell>STATUS</S.TableHeaderCell>
                                    <S.TableHeaderCell>AÇÕES</S.TableHeaderCell>
                                </S.TableRow>
                            </S.TableHeader>
                            <S.TableBody>
                                {currentContracts.map((user, index) => (
                                    <S.TableRow key={index} onClick={() => { console.log(user) }}>
                                        <S.TableCell>{user.IDCOMPRA}</S.TableCell>
                                        <S.TableCell>{handleName(user.NAME, user.CLIENT_NAME)}</S.TableCell>
                                        <S.TableCell>{handleCPF(user.CLIENT_CPF, user.CPF)}</S.TableCell>
                                        <S.TableCell>{formatDateClients(user.PURCHASEDATE)}</S.TableCell>
                                        <S.TableCell>{user.COINS}</S.TableCell>
                                        <S.TableCell>R$ {user.TOTALSPENT && user.TOTALSPENT.toFixed(2)}</S.TableCell>
                                        <S.TableCell>{user.PAYMETHOD ? user.PAYMETHOD : "Indefinido"}</S.TableCell>
                                        <S.TableCell>{handleStatus(user.STATUS ? user.STATUS : 0)}</S.TableCell>
                                        <S.TableCell>
                                            <S.OptionsButtons>
                                                <img onClick={() => { handleOpenValidarModalDEPOSITO(user, "DEPOSITO") }} src='user-edit.png' alt="Editar" />
                                            </S.OptionsButtons>
                                        </S.TableCell>
                                    </S.TableRow>
                                ))}
                            </S.TableBody>
                        </S.Table>
                    </S.TableContainer>

                    <S.PaginationContainer>
                        <S.PaginationButton onClick={() => { handlePaginateFuture("-") }}>
                            Anterior
                        </S.PaginationButton>

                        <S.PaginationButton>
                            {indexOfFirstItem} à {currentPage * itemsPerPage} de {totalPendingPurchases}
                        </S.PaginationButton>

                        <S.PaginationButton onClick={() => { handlePaginateFuture("+") }}>
                            Próxima
                        </S.PaginationButton>
                    </S.PaginationContainer>
                </S.DepositosTable>
            </S.DepositosContent>

            {modal && (
                <S.ModalNovoDeposito>
                    <S.BoxModal>
                        <span onClick={() => setModal(false)} className="closing">X</span>
                        <S.BoxTitle>
                            <h1>REALIZE UMA NOVA COMPRA</h1>
                        </S.BoxTitle>

                        <S.SearchArea>
                            <div>
                                <p>PESQUISE PELO CLIENTE</p>
                                <div className="inputBoxSearch">
                                    <input
                                        placeholder="NOME OU CPF"
                                        value={clientSearch}
                                        onChange={e => setClientSearch(e.target.value)}
                                    />
                                    <button onClick={fetchClients}>PESQUISAR</button>
                                </div>

                                {clientSearchResult && (
                                    <>
                                        <S.SearchedClients>
                                            <div className="columns">
                                                <span>NOME</span>
                                                <span>CPF</span>
                                                <span>EMAIL</span>
                                            </div>
                                            {selectedClient && (
                                                <>
                                                    <div className="rowSelected" style={{ borderTop: "2px solid white", borderBottom: "2px solid white" }}>
                                                        <span style={{ borderRight: "2px solid white" }}>{selectedClient && selectedClient.NAME}</span>
                                                        <span style={{ borderRight: "2px solid white" }}>{selectedClient && selectedClient.CPF}</span>
                                                        <span style={{ borderRight: "2px solid white" }}>{selectedClient && selectedClient.EMAIL}</span>
                                                        <span onClick={() => handleUnSelectClient()} style={{ color: "red" }}>x</span>
                                                    </div>
                                                </>
                                            )}
                                            {selectedClient === null && (
                                                <div className="tabelona">
                                                    {clientSearchResult.map((client, index) => (
                                                        <div onClick={() => handleSelectClient(client)} className="row" style={{ borderTop: "2px solid white", borderBottom: "2px solid white" }}>
                                                            <span style={{ borderRight: "2px solid white" }}>{client && client.NAME}</span>
                                                            <span style={{ borderRight: "2px solid white" }}>{client && client.CPF}</span>
                                                            <span>{client && client.EMAIL}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}

                                        </S.SearchedClients>
                                    </>

                                )}

                                {selectedClient && (
                                    <>
                                        <S.RestContentBox>
                                            <div>
                                                <span>CLIENTE SELECIONADO</span>
                                                <input value={selectedClient ? `${selectedClient.NAME} - ${selectedClient.CPF}` : ''} readOnly />
                                            </div>
                                            <div>
                                                <span>QUANTIDADE DE CONTRATOS</span>
                                                <input type="number" value={coinsQTDE} onChange={e => setCoinsQTDE(e.target.value)} />
                                            </div>
                                            <div>
                                                <span>VALOR POR CONTRATO (R$)</span>
                                                <input type="number" value={valorUni} onChange={e => setValorUni(e.target.value)} />
                                            </div>
                                            <div>
                                                <span>LUCRO CONTRATO (%)</span>
                                                <input type="number" value={lucroFinal} onChange={e => setLucroFinal(e.target.value)} />
                                            </div>
                                            <div>
                                                <span>QUANTIDADE DE MESES</span>
                                                <input type="number" value={mesesCotrato} onChange={e => setMesesContrato(e.target.value)} />
                                            </div>
                                            <div>
                                                <span>VALOR A SER PAGO</span>
                                                <p>R${parseFloat(coinsQTDE) * parseFloat(valorUni)}</p>
                                            </div>
                                            <div>
                                                <span>LUCRO FINAL DO CLIENTE</span>
                                                <p>R${(parseFloat(coinsQTDE) * parseFloat(valorUni)) * (parseFloat(lucroFinal) / 100)}</p>
                                            </div>
                                            <div>
                                                <span>FORMA DE PAGAMENTO</span>
                                                <select value={payMethod} onChange={e => setPayMethod(e.target.value)}>
                                                    <option value="PIX">PIX</option>
                                                    <option value="BOLETO">BOLETO</option>
                                                    <option value="TED">DEPÓSITO</option>
                                                </select>
                                            </div>

                                            <div className="descripBox">
                                                <h1 className="title">Descrição</h1>
                                                <textarea value={descrip} onChange={(e) => setDescrip(e.target.value)} placeholder="Digite Aqui A Descriçãp (opcional)" />
                                            </div>
                                        </S.RestContentBox>
                                    </>
                                )}
                            </div>
                        </S.SearchArea>

                        {selectedClient && (
                            <>
                                <S.ButtonArea>
                                    <h6 className="cancelBtn" onClick={() => setModal(false)}>CANCELAR</h6>
                                    <h6 onClick={handleSave} className="SaveBtn">Salvar</h6>
                                </S.ButtonArea>
                            </>
                        )}
                    </S.BoxModal>
                </S.ModalNovoDeposito>
            )}
        </S.DepositosContainer>
    );
}
