import React, { useState, useEffect, useContext } from "react";
import * as S from './PaginaContratoStyle';
import axios from "axios";
import { formatCPFCriarCliente, formatDate } from "../ASSETS/assets";
import { getDepositos, updateDepositoSuccess, addMonthsToContract } from "../../redux/actions";
import { useDispatch } from "react-redux";
import Loading from "../Loader";
import { AuthContext } from "../../Context/AuthContext";

const base_url = process.env.REACT_APP_API_BASE_URL;
const rota_url = process.env.REACT_APP_API_EDITAR_CONTRATO;
const rota_cancelar = process.env.REACT_APP_CANCELAR_CONTRATO;
const realizar_recompra = process.env.REACT_APP_REALIZAR_RECOMPRA;

function formatDateForInput(dateString) {
    return dateString ? dateString.split(" ")[0] : "";
}

export default function PaginaContrato({ handleClose, contratoData }) {
    const { authState, logout } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [cancelInput, setCancelInput] = useState('');
    const [recompraInput, setRecompraInput] = useState("");
    const [monthsToAdd, setMonthsToAdd] = useState("");
    const [gainToAdd, setGainToAdd] = useState("");
    const [confimAdding, setConfirmAdding] = useState("");

    const [editedData, setEditedData] = useState({
        CLIENT_NAME: '',
        CLIENT_CPF: '',
        IDCOMPRA: '',
        PURCHASEDATE: '',
        COINS: '',
        COINVALUE: '',
        TOTALSPENT: '',
        RENDIMENTO_ATUAL: '',
        YIELDTERM: '',
        STATUS: '',
        MAXIMUMQUOTAYIELD: '',
        PRIMEIRO_RENDIMENTO: '',
        DESCRIPTION: ''
    });
    const [hasChanges, setHasChanges] = useState(false);

    useEffect(() => {
        if (!contratoData) return null;

        const formattedFirstProfit = contratoData.PRIMEIRO_RENDIMENTO
            ? formatDateForInput(contratoData.PRIMEIRO_RENDIMENTO)
            : "";

        setEditedData({
            CLIENT_NAME: contratoData.CLIENT_NAME || '',
            CLIENT_CPF: contratoData.CLIENT_CPF || '',
            IDCOMPRA: contratoData.IDCOMPRA || '',
            PURCHASEDATE: contratoData.PURCHASEDATE || '',
            COINS: contratoData.COINS || '',
            COINVALUE: contratoData.COINVALUE || '',
            TOTALSPENT: contratoData.TOTALSPENT || '',
            RENDIMENTO_ATUAL: `${contratoData.RENDIMENTO_ATUAL ? contratoData.RENDIMENTO_ATUAL.toFixed(2) : contratoData.RENDIMENTO_ATUAL}` || '',
            YIELDTERM: contratoData.YIELDTERM || '',
            STATUS: contratoData.STATUS || '',
            MAXIMUMQUOTAYIELD: contratoData.MAXIMUMQUOTAYIELD || '',
            PRIMEIRO_RENDIMENTO: formattedFirstProfit,
            DESCRIPTION: contratoData.DESCRIPTION || ""
        });

    }, [contratoData]);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedData(prevState => ({
            ...prevState,
            [name]: value
        }));
        setHasChanges(true);
    };


    const handleSave = async () => {

        try {
            setIsLoading(true);

            const primeiroRendimentoString = editedData.PRIMEIRO_RENDIMENTO
                ? `${editedData.PRIMEIRO_RENDIMENTO} 00:00:00` // Adiciona a hora como 00:00:00
                : null;


            const totalSpentFloat = editedData.TOTALSPENT
                ? parseFloat(editedData.TOTALSPENT) // Adiciona a hora como 00:00:00
                : 0;

            const commonData = {
                docId: contratoData.CLIENT_CPF, // CPF do cliente como docId
                IDCONTRATO: contratoData.IDCOMPRA // ID do contrato
            };

            // Dados para a alteração do campo MAXIMUMQUOTAYIELD
            const maximumQuotaYieldData = {
                ...commonData,
                fieldName: 'MAXIMUMQUOTAYIELD',
                fieldNewValue: editedData.MAXIMUMQUOTAYIELD
            };

            // Dados para a alteração do campo RENDIMENTO_ATUAL
            const rendimentoAtualData = {
                ...commonData,
                fieldName: 'RENDIMENTO_ATUAL',
                fieldNewValue: parseFloat(editedData.RENDIMENTO_ATUAL)
            };

            // Dados para a alteração do campo PRIMEIRO_RENDIMENTO
            const primeiroRendimentoData = {
                ...commonData,
                fieldName: 'PRIMEIRO_RENDIMENTO',
                fieldNewValue: primeiroRendimentoString
            };

            const totalSpentData = {
                ...commonData,
                fieldName: 'TOTALSPENT',
                fieldNewValue: totalSpentFloat
            };

            const headers = {
                headers: {
                    Authorization: `Bearer ${authState.accessToken ? authState.accessToken : ""}`
                }
            }

            const res1 = await axios.post(`${base_url}${rota_url}`, maximumQuotaYieldData, headers)
            const res2 = await axios.post(`${base_url}${rota_url}`, rendimentoAtualData, headers)
            const res3 = await axios.post(`${base_url}${rota_url}`, primeiroRendimentoData, headers)
            const res4 = await axios.post(`${base_url}${rota_url}`, totalSpentData, headers)

            if (res1 && res2 && res3 && res4) {
                console.log(res1.data)
                console.log(res2.data)
            }
            handleClose()

            alert("Alterações salvas com sucesso.");
        } catch (error) {
            handleClose()
            console.log("Erro ao salvar as alterações do contrato:", error);
        }
        setIsLoading(false);

    };

    const handleCancelContrato = async () => {

        if (cancelInput != "SIM") {
            alert("Para cancelar, digite SIM");
            return;
        }

        try {
            const response = axios.post(`${base_url}${rota_cancelar}`, {
                userId: contratoData.CLIENT_CPF,
                contractId: contratoData.IDCOMPRA
            })

            if ((await response).data.status === 200) {
                alert("CONTRATO CANCELADO!")
                handleClose();
            } else {
                alert("ERRO AO CANCELAR O CONTRATO");
            }
        } catch (error) {
            alert(`ERRO: ${error}`);
        }
    }

    const handleRecomprar = async () => {
        const docId = contratoData.CLIENT_CPF;
        const IDCOMPRA = contratoData.IDCOMPRA;

        if (recompraInput != "SIM") {
            alert("Para realizar a recompra, digite SIM");
            return;
        }

        setIsLoading(true)
        axios.post(`${base_url}${realizar_recompra}`, { docId, IDCOMPRA }, { headers: { Authorization: `Bearer ${authState.accessToken}` } })
            .then(res => {
                alert("Recompra realizada com sucesso.");
                handleClose();
                setIsLoading(false)

            }).catch(err => {
                console.log(err);
                if(err.response.status === 401 || err.response.status === 403){
                    alert("Sessão Exirada.");
                    logout();
                }else{
                    alert("Erro ao Realizar Recompra.");
                }
                setIsLoading(false)
            })
    }

    const handleAddMonths = async () => {
        if (monthsToAdd <= 0)
            return alert("A Quantidade De Meses A Adicionar Tem Que Ser Maior Que 0.");
        if (gainToAdd <= parseFloat(contratoData.MAXIMUMQUOTAYIELD))
            return alert("A Valorização Final Do Contrato Tem Que Ser Maior Que A Atual.");

        setIsLoading(true);
        await addMonthsToContract(contratoData.CLIENT_CPF, contratoData.IDCOMPRA, gainToAdd, monthsToAdd).then(r => {
            console.log(r);
            alert("Contrato Atualizado Com Sucesso.");
        }).catch(e => {
            console.log(e);
            alert("Erro Ao Atualizar Contrato");
        });
        setMonthsToAdd("");
        setGainToAdd("");
        setConfirmAdding("");
        setIsLoading(false);

    }


    if (!contratoData) return null;

    return (
        <S.PaginaContratoContainer>
            {isLoading && (
                <Loading load={isLoading} />
            )}
            <S.PaginaButtons>
                <S.CloseButton onClick={handleClose}>Fechar</S.CloseButton>
                {hasChanges && (
                    <S.SaveButton onClick={handleSave}>Salvar e Voltar</S.SaveButton>
                )}
            </S.PaginaButtons>

            <h1>Detalhes do Contrato</h1>

            <S.ContratoDataContainer>
                <S.ContratoDataBox>
                    <span>CLIENTE</span>
                    <input
                        type="text"
                        name="CLIENT_NAME"
                        value={editedData.CLIENT_NAME}
                        className="desabilitado"
                        readOnly
                    />
                </S.ContratoDataBox>
                <S.ContratoDataBox>
                    <span>CPF</span>
                    <input
                        className="desabilitado"
                        type="text"
                        value={`${formatCPFCriarCliente(editedData.CLIENT_CPF)}`}
                        readOnly
                    />
                </S.ContratoDataBox>
                <S.ContratoDataBox>
                    <span>ID</span>
                    <input
                        type="text"
                        name="IDCOMPRA"
                        value={editedData.IDCOMPRA}
                        className="desabilitado"
                        readOnly
                    />
                </S.ContratoDataBox>
                <S.ContratoDataBox>
                    <span>DATA DA COMPRA</span>
                    <input
                        type="text"
                        name="PURCHASEDATE"
                        value={formatDate(editedData.PURCHASEDATE)}
                        className="desabilitado"
                        readOnly
                    />
                </S.ContratoDataBox>
                <S.ContratoDataBox>
                    <span>PRIMEIRO RENDIMENTO</span>
                    <input
                        type="date" // Mudança para tipo date
                        name="PRIMEIRO_RENDIMENTO"
                        value={editedData.PRIMEIRO_RENDIMENTO}
                        onChange={handleInputChange} // Mantenha onChange para capturar mudanças
                    />
                </S.ContratoDataBox>

                <S.ContratoDataBox>
                    <span>QUANTIDADE DE CONTRATOS</span>
                    <input
                        type="text"
                        name="COINS"
                        value={editedData.COINS}
                        className="desabilitado"
                        readOnly
                    />
                </S.ContratoDataBox>
                <S.ContratoDataBox>
                    <span>VALOR UNI. CONTRATO</span>
                    <input
                        type="text"
                        name="COINVALUE"
                        value={editedData.COINVALUE}
                        className="desabilitado"
                        readOnly
                    />
                </S.ContratoDataBox>
                <S.ContratoDataBox>
                    <span>VALOR INVESTIDO</span>
                    <input
                        type="text"
                        name="TOTALSPENT"
                        value={editedData.TOTALSPENT}
                        className="desabilitado"
                    // className="desabilitado"
                    // readOnly
                    />
                    {/* <h4>Adicionar Saldo Bônus</h4> */}
                </S.ContratoDataBox>

                <S.ContratoDataBox>
                    <span>FINALIZA EM</span>
                    <input
                        type="text"
                        name="YIELDTERM"
                        value={formatDate(editedData.YIELDTERM)}
                        className="desabilitado"
                        readOnly
                    />
                </S.ContratoDataBox>
                <S.ContratoDataBox>
                    <span>TOTAL DE LUCRO ATUALMENTE (%)</span>
                    <input
                        type="text"
                        name="RENDIMENTO_ATUAL"
                        value={editedData.RENDIMENTO_ATUAL}
                        onChange={handleInputChange} // Editável
                    />
                </S.ContratoDataBox>
                <S.ContratoDataBox>
                    <span>VALOR LUCRO DO CONTRATO (%)</span>
                    <input
                        type="text"
                        name="MAXIMUMQUOTAYIELD"
                        value={editedData.MAXIMUMQUOTAYIELD}
                        onChange={handleInputChange} // Editável
                    />
                </S.ContratoDataBox>
                <S.ContratoDataBox>
                    <span>VALOR LUCRO DO CONTRATO (%)</span>
                    <input
                        type="text"
                        name="DESCRIPTION"
                        value={editedData.DESCRIPTION || ""}
                        readOnly
                        className="desabilitado"
                    />
                </S.ContratoDataBox>
            </S.ContratoDataContainer>

            <S.Funcoes>
                <S.RealizarRecompra>
                    <span>DESEJA REALIZAR A RECOMPRA?</span>
                    <div>
                        <input
                            onChange={(e) => setRecompraInput(e.target.value)}
                            value={recompraInput}
                            type="text"
                            placeholder="Digite SIM para confirmar a recompra" />
                        <button onClick={handleRecomprar}>CONFIRMAR</button>
                    </div>
                </S.RealizarRecompra>

                <S.RevalidarContrato>
                    <span className="titulo">PROLONGAR/REATIVAR CONTRATO</span>
                    <div className="boxReativar">
                        <div className="box">
                            <span className="titleBox">Meses à Adicionar</span>
                            <input value={monthsToAdd} onChange={(e) => setMonthsToAdd(e.target.value)} className="inputText" placeholder="Mín: 1" />
                        </div>
                        <div className="box">
                            <span className="titleBox">Novo Teto de Valorização</span>
                            <input value={gainToAdd} onChange={(e) => setGainToAdd(e.target.value)} className="inputText" placeholder="Ex: 155%, 160%, 180%..." />
                        </div>
                        <div className="box">
                            <span className="titleBox">Digite SIM Para Confirmar</span>
                            <input value={confimAdding} onChange={(e) => setConfirmAdding(e.target.value)} className="inputText" placeholder="" />
                        </div>
                    </div>
                    {confimAdding && confimAdding.trim() === "SIM" && (
                        <button onClick={handleAddMonths} className="confirm">CONFIRMAR</button>
                    )}
                </S.RevalidarContrato>
            </S.Funcoes>
        </S.PaginaContratoContainer>
    );
}
