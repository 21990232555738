import styled from "styled-components";

export const PaginationInfo = styled.span`
  color: #f2f2f2;
`;

export const SaquesContainer = styled.div`
    width: 100%;
    height: 100vh;
    overflow:auto;
    box-sizing: border-box;
    padding: 40px 40px;
    background-image: url('/textura.jpg');
    background-size: cover;
    z-index: 0; 
    color: #f2f2f2;
    position: relative;

    @media (max-width: 1000px){
        padding: 20px 20px;
    } 
`;

export const SaquesFirstContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    box-sizing: border-box;
    align-items: center;

    @media (max-width: 915px){
        flex-direction: column;
        gap: 10px;
    }
`;

export const AreaTitle = styled.h1`
    text-shadow: 1px 1px 2px rgba(255,255,255,0.2);
    cursor: pointer;
    margin: 0;
    transition: .3s;
    color: #000000;

    &:hover{
        text-shadow: 1px 1px 2px rgba(255,255,255,0);
        color: #FFC300;
        padding-left: 20px;
    }
`;

export const AddSaques = styled.button`
    padding: 10px 20px;
    box-sizing: border-box;
    background-color: #49beb7;
    color: #f2f2f2;
    border: 0;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.6);
    cursor: pointer;
    transition: .3s;

    &:hover{
        background-color: #085f63;
        color: #f1f1f1;
    }
`;

export const SaquesContent = styled.div`
    width: 100%;
    background: linear-gradient(-60deg, #313632, #000000 , #313632, #000000, #000000, #313632);  
    box-sizing: border-box;
    margin-top: 50px;
    padding-bottom: 30px;
    box-shadow: 3px 3px 1px black;

    @media (max-width: 915px){
        padding: 20px;
    }
`;

export const SearchBar = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 30px;
    background: linear-gradient(-60deg, #313632, #000000 , #313632, #000000, #000000, #313632); 
    display: grid;
    grid-template-columns: 8fr 1fr;
    gap: 10px;

    input{
        box-sizing: border-box;
        width: 100%;
        height: 60px;
        background: linear-gradient(-60deg, #313632, #000000 , #313632, #000000, #000000, #313632); 
        border: 3px solid rgba(255, 255, 255, 0.2);
        padding-left: 30px;
        box-shadow: 1px 1px 2px black;
        color: rgba(255, 195, 0, 1);
        font-weight: 600;
        font-size: 18px;
        text-transform: uppercase;
    }

    button{
        width: 200px;
        height: 60px;
        border: 0;
        border-radius: 6px;
        background: linear-gradient(to right, rgba(190, 120, 0, 1), rgba(190, 200, 0, 1));
        color: black;
        font-size: 18px;
        cursor: pointer;
        transition: .3s;

        &:hover{color: white;}
    }

    @media (max-width: 915px){
        padding: 0px;
    }
`;

export const SaquesTable = styled.div`
    width: 100%;
    background: linear-gradient(-60deg, #313632, #000000 , #313632, #000000, #000000, #313632);  
    box-sizing: border-box;
    padding: 0 30px 0 30px;
    max-height: 800px;
    overflow: auto;
    flex-direction: column;
    display: flex;
    justify-content: center;

    @media (max-width: 1000px){
        min-height: 300px;
        padding: 0;
        border: 2px solid rgba(0,0,0,0.2);
        max-height: 250px;
    }
`;

export const AtualizarData = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    span{
        font-weight: 500;
        color: white;
        transition: .3s;
        cursor: pointer;
        margin-bottom: 5px;
        width: max-content;

        &:hover{
            color: rgba(255, 255, 255, 0.5);
        }
    }
`;

export const TableContainer = styled.div`
    width: 100%;
    box-sizing: border-box;    
    overflow: auto;
`;

export const Table = styled.table`
    width: 100%;
    overflow: auto; 
    border-collapse: collapse;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.2);
    position: relative;
`;

export const TableHeader = styled.thead`
    color: #FFFFFF;
`;

export const TableRow = styled.tr`
    background: linear-gradient(-60deg, #313632, #000000 , #313632, #000000, #000000, #313632); 
    color: #B17D2A;

    &:nth-child(even) {
        background: linear-gradient(-60deg, #313632, #000000 , #313632, #000000, #000000, #313632); 
    }
`;

export const TableHeaderCell = styled.th`
    padding: 15px;
    text-align: center;
    color: #FFFFFF;
    background: linear-gradient(-60deg, #000000, #000000 , #000000, #000000, #000000, #000000); 
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    min-width: 100px; 
    border: 1px solid #B17D2A;
    white-space: nowrap;
`;

export const TableBody = styled.tbody`
    background-color: white;
`;

export const TableCell = styled.td`
    // padding: 15px;
    padding: 5px 10px;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    min-width: 100px; /* Ajuste conforme necessário */
    white-space: nowrap;
    border: 1px solid #B17D2A;

    .button{
        width: 100%;
        height: 35px;
        border-radius: 6px;

        cursor: pointer;
        border: 0;
    }

    .cancel{
        background: rgba(255, 90, 0, 1);
        color: white;
    }

    .seeAccount{
        background: rgba(0, 140, 255, 1);
        color: white;
    }
`;

export const OptionsButtons = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    img{
        width: 40px;
        cursor: pointer;
        transition: .3s;

        &:hover{
            transform: scale(1.3);
        }
    }
`;

export const AccountInfo = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;

    span{
        text-align: center;
        font-size: 12px;
        font-weight: 600;
        color: #B17D2A;
    }
`;

export const AccountInfoSelect = styled.select`
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    height: 35px;
    // padding: 10px;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background: linear-gradient(-60deg, #313632, #000000 , #313632, #000000, #000000, #313632);  
    color: white;
    font-weight: 600;
`;


export const OpcaoSelecionarInput = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
    gap: 5px;
    margin-bottom: 10px;
    align-items: center;

    div {
        width: 15px;
        height: 15px;
        border: 1px solid white;
        border-radius: 8px;
        transition: background-color 0.3s;
        cursor: pointer;
    }

    label {
        font-weight: 600;
        transition: color 0.3s;
    }

`;


export const OpcaoDescelecionarInput = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
    gap: 5px;
    margin-bottom: 10px;
    align-items: center;

    div {
        width: 15px;
        height: 15px;
        border: 1px solid white;
        border-radius: 8px;
        background: rgba(20,100,255, 1);
        transition: background-color 0.3s;
        cursor: pointer;

    }

    label {
        font-weight: 600;
        transition: color 0.3s;
        color: rgba(20,100,255, 1);

    }

`;


export const Selection = styled.div`
    width: 15px;
    height: 15px;
    border-radius: 8px;
    border: 1px solid white;
    background-color: ${({ bgColor }) => bgColor || 'transparent'}; // Use a cor passado ou 'transparent' como padrão.
`;


export const ExtrairEOrdenar = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: end;
    gap: 10px;
    padding: 10px 0;

    select{
        width: 200px;
        height: 30px;
        color: white;
        border-radius: 6px;
        border: 2px solid rgba(255, 255, 255, 0.2);
        background: linear-gradient(to bottom, rgba(0,0,0,1), rgba(255,255,255,0.2), rgba(0,0,0,1));
    }


    .divPaiFilter{
        display: flex;
        flex-direction: column;
        width: max-content;
        border-radius: 8px;
        gap: 5px;
        box-sizing: border-box;

        .botaoExtrairTabela{
            width: 200px;
            height: 30px;
            background: linear-gradient(to right, rgba(0,0,0,0.9), rgba(355,355,255,0.4));
            border: 1px solid rgba(255, 255, 255, 0.8);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            cursor: pointer;
            transition: .3s;
        }

        .selectExtrairTabela{
            width: 200px;
            height: 30px;
            background: linear-gradient(to bottom, black, rgba(0,0,0,0.8), black);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            cursor: pointer;
            transition: .3s;    
            color: white;
        }
    }  
`;


export const PaginationContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 5px;
`;

export const PaginationButton = styled.button`
    padding: 8px 12px;
    border: 1px solid #ddd;
    background-color: ${props => (props.active ? '#007bff' : '#fff')};
    color: ${props => (props.active ? '#fff' : '#007bff')};
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;

    &:hover {
        background-color: #007bff;
        color: #fff;
    }

    &:disabled {
        background-color: #ddd;
        color: #aaa;
        cursor: not-allowed;
    }
`;


export const Filtros = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const FiltroStatus = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;

    .box{
        display: flex;
        align-items: center;
        flex-direction: column;

        .title{
            font-size: 26px;
            color: white;
        }

        .selectBox{
            width: 350px;
            height: 45px;
            border-radius: 6px;
            text-align: center;
            font-size: 18px;
            color: white;
            background: linear-gradient(to right, rgba(20, 20, 20, 1), rgba(50, 50, 50, 1), rgba(20, 20, 20, 1));
        }

        .filterButton{
            margin-top: 10px;
            width: 100%;
            height: 45px;
            border-radius: 6px;
            background: rgba(0, 200, 180, 1);
            color: black;
            transition: .3s;
        }
    }
`;

export const ModalConta = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.8);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .box{
        width: 650px;
        padding: 40px 30px;
        box-sizing: border-box;
        background: linear-gradient(to right, rgba(210, 210, 210, 1), rgba(240, 240, 240, 1), rgba(210, 210, 210, 1));
        position: relative;
        border-radius: 6px;

        .title{
            width: 100%;
            font-size: 22px;
            color: black;
        }

        .close{
            position: absolute;
            top: 10px;
            right: 20px;
            color: red;
            font-size: 22px;
            font-weight: 600;
            cursor: pointer;
            transition: .3s;

            &:hover{
                color: black;
            }
        }

        .account{
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            gap: 20px;

            .boxzinha{
                width: 100%;
                height: 40px;
                background: linear-gradient(-45deg, rgba(20, 20, 20, 1), rgba(50, 50, 50, 1), rgba(20, 20, 20, 1));
                color: white;
                border-radius: 6px;
                font-size: 18px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        @media (max-width: 900px){
            width: 350px;
        }

        .ask{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;
            margin-top: 20px;

            button{
                width: 40%;
                height: 40px;
                border-radius: 6px;
                border: 0;
                cursor: pointer;
                transition: .3s;
                background: rgba(220, 50, 0, 1);
                font-size: 18px;
                color: white;

                &:hover{
                    transform: scale(0.99);
                }
            }
        }
    }
`;

// export const nome = styled.div``;

// export const nome = styled.div``;
