import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import * as S from './AnteciparLucroStyle';
import Loading from "../Loader";
import axios from "axios";
import { AuthContext } from "../../Context/AuthContext";

const base_route = process.env.REACT_APP_API_BASE_URL;
const ANTECIPAR = process.env.REACT_APP_ANTECIPAR_LUCRO;

export default function AnteciparLucro() {
    const { logout, getPurchaseById, purchaseForSelect, handleUnselectPurchaseForSelect, authState } = useContext(AuthContext);

    const [searchTerm, setSearchTerm] = useState("");
    const [selectedContract, setSelectedContract] = useState(null);
    const [aditionalValue, setAditionalalue] = useState("");
    const [loading, setLoading] = useState(false);
    const [descrip, setDescrip] = useState("Antecipação");

    const fetchContract = async () => {
        setLoading(true);
        try {
            await getPurchaseById(searchTerm);
        } catch (e) {
            console.log(e)
        }
        setLoading(false);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSendValue = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${base_route}${ANTECIPAR}`, {
                userId: selectedContract.CPF,
                contractId: selectedContract.IDCOMPRA,
                increasement: aditionalValue,
                descrip: descrip
            }, {
                headers: { Authorization: `Bearer ${authState.accessToken}` }
            });

            if (response.data.status === 200) {
                alert("Saldo antecipado com sucesso");
                setLoading(false);
            }
            else {
                alert(`Erro: ${response.data.data}, status : ${response.data.status}`);
                setLoading(false);
            };

        } catch (error) {
            console.log(error)
            setLoading(false);

            if (error.response.status === 401 || error.response.status === 403) {
                alert("Sessão Expirada.");
                logout();
            } else {
                alert(`Houve um erro: ${error}`);
            }
        }

        setSearchTerm('');
        setSelectedContract(null)
        handleUnselectPurchaseForSelect()
    }

    useEffect(() => {
        if (purchaseForSelect)
            setSelectedContract(purchaseForSelect)
    }, [purchaseForSelect])

    return (
        <S.AnteciparLucroContainer>
            <h1>ANTECIPAÇÃO DE LUCRO</h1>
            <Loading load={loading} />
            <S.ClientSearch>
                <h4>PESQUISE PELO CONTRATO</h4>
                <div className="inputBoxFilter">
                    <input
                        type="text"
                        placeholder="ID DO CONTRATO"
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                    <button onClick={fetchContract}>OBTER</button>
                </div>
            </S.ClientSearch>

            {purchaseForSelect && (
                <>
                    <h4>Encontrado: #{purchaseForSelect.IDCOMPRA} DE {purchaseForSelect.NAME}</h4>
                    <S.QuandoDeseja>
                        <span>QUANTO DESEJA ANTECIPAR? (R$)</span>
                        <input
                            type="number"
                            value={aditionalValue}
                            onChange={(e) => setAditionalalue(e.target.value)}
                        />

                        <span>DESCRIÇÃO (Opcional)</span>
                        <input
                            type="text"
                            value={descrip}
                            onChange={(e) => setDescrip(e.target.value)}
                        />
                        <button onClick={handleSendValue}>ADICIONAR</button>
                    </S.QuandoDeseja>
                </>
            )}

        </S.AnteciparLucroContainer>
    )
}