import React, { useState, useContext, useEffect } from "react";
import * as S from './PlusAnualStyle';
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loader";
import axios from "axios";
import { AuthContext } from "../../Context/AuthContext";

const base_route = process.env.REACT_APP_API_BASE_URL;
const ADD_PLUS = process.env.REACT_APP_ADICIONAR_PLUS_ANUAL;

export default function PlusAnual() {
    const { clientsForSelect, getPurchaseById, purchaseForSelect, authState } = useContext(AuthContext);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedContract, setSelectedContract] = useState(null);
    const [plusValue, setPlusValue] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };


    const fetchContract = async () => {
        setLoading(true);
        await getPurchaseById(searchTerm);
        setLoading(false);
    };

    useEffect(() => {
        setSelectedContract(purchaseForSelect)
    }, [purchaseForSelect])

    const handleSelectContract = (contract) => {
        setSelectedContract(contract);
        setSearchTerm("");
    };

    const handleUnSelectContract = () => {
        setSelectedContract(null);
    };

    const handleSendPlus = async () => {
        if (!plusValue || isNaN(plusValue)) {
            alert("Por favor, insira um valor válido.");
            return;
        }

        const headers = {
            headers: {
                Authorization: `Bearer ${authState.accessToken ? authState.accessToken : ""}`
            }
        }

        setLoading(true);
        console.log(selectedContract)
        try {
            await axios.post(`${base_route}${ADD_PLUS}`, {
                docId: selectedContract.CPF, 
                IDCOMPRA: selectedContract.IDCOMPRA,
                PLUS: parseFloat(plusValue)
            }, headers).then(res => {
                alert("PLUS adicionado com sucesso.")
                console.log(res);
                setPlusValue("");
                setSelectedContract(null);
                setLoading(false);
            }).catch(error => {
                console.log(error);
                alert("Erro ao adicionar PLUS")
                setPlusValue("");
                setSelectedContract(null);
                setLoading(false);
            });

        } catch (error) {
            alert(`Erro ao adicionar PLUS: ${error.message}`);
            setLoading(false);
        }
    };

    return (
        <S.PLUSContainer>
            <Loading load={loading} />
            <h1>ADICIONAR PLUS</h1>

            <S.ClientSearch>
                <h4>PESQUISE PELO CONTRATO</h4>
                <div className="inputBoxFilter">
                    <input
                        type="text"
                        placeholder="ID DO CONTRATO"
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                    <button onClick={fetchContract}>OBTER</button>
                </div>
            </S.ClientSearch>

            <S.SearchArea>
                {clientsForSelect && clientsForSelect.length > 0 && (
                    <S.SearchedClients>
                        <div className="columns">
                            <span>ID</span>
                            <span>ID CLIENTE</span>
                            <span>VALOR DO CONTRATO</span>
                        </div>
                    </S.SearchedClients>
                )}
            </S.SearchArea>

            {purchaseForSelect && (
                <>
                    <h4>Selecionado: #{purchaseForSelect.IDCOMPRA} DE {purchaseForSelect.NAME}</h4>
                    <S.QuandoDeseja>
                        <span>QUANTO SERÁ O PLUS DO CONTRATO? (%)</span>
                        <input
                            type="number"
                            value={plusValue}
                            onChange={(e) => setPlusValue(e.target.value)}
                        />
                        <button onClick={handleSendPlus}>ADICIONAR</button>
                    </S.QuandoDeseja>
                </>
            )}
        </S.PLUSContainer>
    );
}
