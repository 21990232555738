import styled, { keyframes } from 'styled-components';



export const SideBarAbsolute = styled.div`
  position: fixed;
  z-index: 999999999;
  top: 0;
  left: 0;
  height: 100vh;
`;

export const SideBarContainer = styled.div`
  background: linear-gradient(-70deg, #000000, #000000, #302d2d, #000000, #000000);
  width: ${({ expanded }) => (expanded ? "350px" : "0px")};
  height: calc(100vh - 80px);
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  overflow: hidden;
  transition: width 0.3s ease;
  position: relative;
  justify-content: space-between; 
  box-shadow: 2px 0px 2px rgba(0,0,0,0.6);

  @media (max-width: 1000px){
    width: ${({ expanded }) => (expanded ? "100vw" : "0px")};
  }
`;

export const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  align-items: center;
  padding-top: 30px;
`;

export const LogoText = styled.span`
  color: #e3e3e3;
  font-size: 24px;
  box-sizing: border-box;

  img {
    width: 160px;
    opacity: 0.7;

    &:hover {
      opacity: 0.7;
    }
  }
`;

export const NavItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
`;

export const NavItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  gap: 5px;
  overflow-y: auto;
  max-height: 450px;
  overflow-x: hidden;

  @media (max-width: 1000px) {
    max-height: 350px;
    overflow-y: scroll;
  }

`;



export const NavItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s, transform 0.3s;
  cursor: pointer;
  box-sizing: border-box;
  background: linear-gradient(-60deg, #000000, #000000, #000000);

  a {
    text-decoration: none;
    color: white;
    font-weight: 600;
    width: 100%;
    font-size: 18px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
  }

  &:hover {
    background: #B17D2A;
    color: rgba(255,255,255,1);
    transform: scale(1.05);
  }

  @media (max-width: 1000px) {
    a {
      height: 50px;
    }
  }
`;

export const NavFooter = styled.div`
  width: 100%;
  height: 80px;
  background-color: rgba(0, 8, 20, 0.5);
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.6);
  font-size: 22px;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: .3s;

  &:hover {
    color: rgba(255, 255, 255, 1);
  }

  @media (max-width: 1000px) {
    height: 60px;
  }
`;

export const ToggleButton = styled.button`
  width: 100%;
  background-color: ${({ expanded }) => (expanded ? "#000000" : "transparent")};
  box-shadow: ${({ expanded }) => (expanded ? "2px 0px 2px rgba(0,0,0,0.6);" : "none")};
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  flex-direction: ${({ expanded }) => (expanded ? "row" : "column")};
  align-items: center;
  justify-content: ${({ expanded }) => (expanded ? "center" : "center")};
  padding-left: ${({ expanded }) => (expanded ? "20px" : "20px")};
  padding-top: ${({ expanded }) => (expanded ? "20px" : "20px")};
  border-radius: 0;

  &:hover{
    background-color: ${({ expanded }) => (expanded ? "#000000" : "transparent")};
  }
`;

export const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
`;

export const ArrowIcon = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 10px;
  animation: ${({ expanded }) =>
    expanded ? rotateAnimation : "none"} 0.3s linear forwards;
`;