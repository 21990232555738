import React, { useEffect, useState, useContext } from "react";
import { useSelector } from 'react-redux';
import * as S from './RedefinirEmailStyle';
import axios from "axios";
import Loading from "../Loader";
import { AuthContext } from "../../Context/AuthContext";

const BASE = process.env.REACT_APP_API_BASE_URL;
const ROUTE = process.env.REACT_APP_REDEFINE_AUTH;

export default function RedefinirEmail() {
    const { getClientsForSelect, clientsForSelect, authState } = useContext(AuthContext);
    const [cpfCliente, setCpfCliente] = useState("");
    const [nomeCliente, setNomeCliente] = useState("");
    const [selected, setSelected] = useState(null);
    const [email, setEmail] = useState("");
    const [pass, setPass] = useState("");
    const [confirmPass, setConfirmPass] = useState("");
    const [load, setLoad] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [showResults, setShowResults] = useState(false);

    const fetchClients = async () => {
        setLoad(true);
        try {
            await getClientsForSelect(50, 0, nomeCliente, "crescente", "");
        } catch (e) {
            console.log(e)
        }
        setLoad(false);
    };


    const handleRedefinirEmail = async () => {
        setLoad(true)
        if (pass !== confirmPass) {
            alert("As senhas não coincidem!");
            setLoad(false);
            return;
        }

        const headers = {
            headers: {
                Authorization: `Bearer ${authState.accessToken ? authState.accessToken : ""}`
            }
        }

        try {
            axios.post(`${BASE}${ROUTE}`, {
                newEmail: email,
                newPassword: pass,
                cpfCliente: cpfCliente
            }, headers).then(res => {
                console.log("Authentication Redefined");
                alert("Email e Senha alterados com sucesso.")
                console.log(res);
                setLoad(false);
                setConfirmPass("")
                setPass("")
                setCpfCliente("")
                setEmail("")
                setSelected(null);
                setNomeCliente("");
            }).catch(error => {
                console.log("Ocorreu um erro ao atualizar email e senha: ");
                console.log(error);
                alert("Ocorreu um erro ao redefinir email e senha.");
                setLoad(false);
            })
        } catch (error) {
            setLoad(false);
            console.error("Erro ao redefinir email:", error);
            if (error.code === 'auth/requires-recent-login') {
                alert("Por favor, faça login novamente e tente esta operação.");
            } else {
                alert("Erro ao redefinir email. Por favor, tente novamente.");
            }
        }
    };

    useEffect(() => {
        if (selected) {
            setEmail(selected.EMAIL || "");
            setCpfCliente(selected.CPF || "");
        }
    }, [selected]);

    const handleSelectClient = (client) => {
        setSelected(client);
        setNomeCliente(client.NAME);
        setShowResults(false);
    };

    const handleUnSelectClient = () => {
        setSelected(null);
    };

    return (
        <S.ContainerRedefinirSenha>
            <Loading load={load} />
            <S.ImageBackground src="logo-golden.png" />

            <S.ContainerTitle>Controle de Usuário</S.ContainerTitle>

            <S.ContainerContent>
                <S.RedefineBox>
                    <h2>Redefina Email e senha</h2>

                    <S.Box className="meuDeus">
                        <p>Pesquise</p>
                        <div className="inputBoxSearch">
                            <input
                                type="text"
                                value={nomeCliente}
                                placeholder="NOME OU CPF"
                                onChange={(e) => { setNomeCliente(e.target.value); }}
                                onFocus={() => setShowResults(true)}
                            />
                            <button onClick={() => fetchClients()}>PESQUISAR</button>
                        </div>

                        <S.PesquisaContainer>
                            {clientsForSelect && (
                                <>
                                    <S.SearchedClients>
                                        <div className="columns">
                                            <span>NOME</span>
                                            <span>CPF</span>
                                            <span>EMAIL</span>
                                        </div>
                                        {selected && (
                                            <>
                                                <div className="rowSelected" style={{ borderTop: "2px solid white", borderBottom: "2px solid white" }}>
                                                    <span style={{ borderRight: "2px solid white" }}>{selected && selected.NAME}</span>
                                                    <span style={{ borderRight: "2px solid white" }}>{selected && selected.CPF}</span>
                                                    <span style={{ borderRight: "2px solid white" }}>{selected && selected.EMAIL}</span>
                                                    <span onClick={() => handleUnSelectClient()} style={{ color: "red" }}>x</span>
                                                </div>
                                            </>
                                        )}
                                        {selected === null && (
                                            <div className="tabelona">
                                                {clientsForSelect.map((client, index) => (
                                                    <div onClick={() => handleSelectClient(client)} className="row" style={{ borderTop: "2px solid white", borderBottom: "2px solid white" }}>
                                                        <span style={{ borderRight: "2px solid white" }}>{client && client.NAME}</span>
                                                        <span style={{ borderRight: "2px solid white" }}>{client && client.CPF}</span>
                                                        <span>{client && client.EMAIL}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        )}

                                    </S.SearchedClients>
                                </>

                            )}
                        </S.PesquisaContainer>
                    </S.Box>


                    {selected && (
                        <S.SelectedContainer>
                            <>
                                <S.BoxInfo>
                                    <p>CPF Cliente</p>
                                    <input
                                        readOnly
                                        type="text"
                                        value={cpfCliente}
                                    />
                                </S.BoxInfo>

                                <S.BoxInfo>
                                    <p>Novo Email</p>
                                    <input
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </S.BoxInfo>

                                <S.BoxInfo>
                                    <p>Nova Senha</p>
                                    <input
                                        type="text"
                                        value={pass}
                                        onChange={(e) => setPass(e.target.value)}
                                    />
                                </S.BoxInfo>

                                <S.BoxInfo>
                                    <p>Confirme a Senha</p>
                                    <input
                                        type="text"
                                        value={confirmPass}
                                        onChange={(e) => setConfirmPass(e.target.value)}
                                    />
                                </S.BoxInfo>

                                {(cpfCliente.trim() !== "" && email.trim() !== ""
                                    && pass.trim() !== "" && confirmPass.trim() !== "") && (
                                        <S.RedefineButton onClick={handleRedefinirEmail}>Redefinir</S.RedefineButton>
                                    )}
                            </>
                        </S.SelectedContainer>
                    )}
                </S.RedefineBox>
            </S.ContainerContent>
        </S.ContainerRedefinirSenha>
    )
}
