import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import * as S from './SideBarStyle';
import { useContext } from 'react';
import { AuthContext } from '../../Context/AuthContext';

export default function SideBar({ NAV_LINKS }) {
  const [expanded, setExpanded] = useState(false);
  const {logout} = useContext(AuthContext)

  const toggleSidebar = () => {
    setExpanded(!expanded);
  };

  const handleLogout = () => {
    logout()
  };

  return (
    <S.SideBarAbsolute>
      <S.ToggleButton expanded={expanded} onClick={toggleSidebar}>
        <S.ArrowIcon
          src="menu-gold.svg"
          expanded={expanded}
        />

      </S.ToggleButton>
      <S.SideBarContainer expanded={expanded}>

        {expanded && (
          <>
            <S.LogoContainer>
              <S.LogoText>
                <img src="logo-golden-ouro.png" />
              </S.LogoText>
            </S.LogoContainer>
            <S.NavItemsWrapper>
              <S.NavItemsContainer>
                {NAV_LINKS.map((link, index) => (
                  <S.NavItem key={index}>
                    <NavLink to={link.path} activeClassName="active-link">
                      {link.name}
                    </NavLink>
                  </S.NavItem>
                ))}
              </S.NavItemsContainer>
            </S.NavItemsWrapper>
            <S.NavFooter onClick={handleLogout}>LOGOUT</S.NavFooter>   
          </>
        )}
      </S.SideBarContainer>
    </S.SideBarAbsolute>
  );
}
