import styled from "styled-components";

export const ExtrairContainer = styled.div`
    width: 100%;
    background-image: url('/textura.jpg');
    background-size: cover;
    z-index: 0; 
    min-height: 100vh;
    padding: 60px 40px 40px 40px;
    display: flex;
    justify-content: start;
    box-sizing: border-box;
    flex-direction: column;

    @media (max-width: 915px){
        padding-left: 30px;
    }
`;

export const TypeAndFilter = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const SelectBox = styled.div`
    width: 400px;
    display: flex;
    align-items: center;
    flex-direction: column;

    .title{
        text-align: center;
        font-size: 42px;
        color: black;
    }

    .selectInfo{
        width: 100%;
        box-sizing: border-box;
        padding: 0;
        text-align: center;
        height: 45px;
        border-radius: 6px;
        background: linear-gradient(to right, rgba(20, 20, 20, 1), rgba(50, 50, 50, 1), rgba(20, 20, 20, 1));
        color: white;
        font-size: 18px;
    }
`;

export const Filters = styled.div`
    width: 100%;
    display: flex;
    gap: 20px;
    margin-top: 20px;
    justify-content: center;

    .box{
        width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title{
            text-align: center;
            font-size: 22px;
            font-weight: 600;
            color: black;
        }

        .selectInfo{
            width: 100%;
            box-sizing: border-box;
            padding: 0;
            text-align: center;
            height: 40px;
            border-radius: 6px;
            background: linear-gradient(to right, rgba(20, 20, 20, 1), rgba(50, 50, 50, 1), rgba(20, 20, 20, 1));
            color: white;
            font-size: 18px;
        }
    }
`;

export const Download = styled.div`
    width: 100%;
    margin-top: 20px;

    button{
        transition: .3s;
        cursor: pointer;
        width: 100%;
        height: 45px;
        border-radius: 6px;
        background: rgba(20, 200, 0, 1);
        font-size: 18px;

        &:hover{
            transform: scale(0.99);
        }
    }
`;

// export const nome = styled.div``;

// export const nome = styled.div``;
