import styled from "styled-components";

export const PaginaClienteContainer = styled.div`
    width: 100%;
    height: 100vh;
    overflow: hidden;
    box-sizing: border-box;
    padding: 40px 40px;
    background: linear-gradient(to right, rgba(20, 20, 20, 1), rgba(50, 50, 50, 1), rgba(20, 20, 20, 1));
    color: #f2f2f2;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    overflow-y: scroll;
    
    @media (max-width: 1000px){
        padding: 10px 10px;
    }

    h1 {
        margin-top: 40px;
    }

`;


export const ClientDataContainer = styled.div`
    width: 100%;
    display: grid;
    overflow: auto;
    gap: 20px;
    margin-top: 40px;
    grid-template-columns: 2fr 2fr 2fr;

    @media(max-width: 1400px){
        grid-template-columns: 2fr 2fr;
    }

    @media(max-width: 900px){
        grid-template-columns: 2fr;
    }
`;

export const ClientDataBox = styled.div`
     width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    span{
        font-size: 26px;
        text-align: center;
        width: 100%;
        font-weight: 500;
    }
    
    input{
        width: 100%;
        height: 50px;
        border: 0;
        box-shadow: 4px 4px 2px rgba(0,0,0,0.7);
        font-size: 22px;
        box-sizing: border-box;
        padding-left: 20px;
        border: 3px solid rgba(255, 255, 255, 0.2);
    }

    h4{
        margin: 0;
        font-weight: 500;
        font-size: 18px;
        color: rgba(0,220,0,1);
        width: 100%;
        text-align: end;
        cursor: pointer;
        transition: .3s;

        &:hover{color: white;}
    }

    .desabilitado{
        background-color: rgba(50,50,50,1);
        color: white;
    }

    @media (max-width: 1000px){
        span{
            font-size: 16px;
        }

        input{
            font-size: 18px;
        }
    }
`;


export const PaginaButtons = styled.div`
    width: 100%;

    display: flex;
    gap: 10px;
    justify-content: end;
`;


export const CloseButton = styled.button`

    background-color: #FFC300;
    color: #000814;
    border: 0;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #000814;
        color: #FFC300;
    }
`;

export const SaveButton = styled.button`

    background: linear-gradient(to bottom, #0c9b18, #6acc1a);
    color: #000814;
    border: 0;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #000814;
        color: #FFC300;
    }
`;
// export const nome = styled.div``;

// export const nome = styled.div``;

// export const nome = styled.div``;
